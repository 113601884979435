<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <div>
            <div class="font-weight-bold text-primary">
              Satış Föy No
            </div>
            <div>{{ dataItem.order_number }}</div>
          </div>
        </b-col>
        <b-col>
          <div>
            <div class="font-weight-bold text-primary">
              Müşteri Bilgisi
            </div>
            <div>{{ dataItem.customer }}</div>
            <div class="font-small-2 text-muted">
              {{ dataItem.customer }}
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-center">
            <div class="font-weight-bold text-primary">
              Toplam Evrak
            </div>
            <div>{{ dataItem.counts }} Adet</div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Yüklenen Evraklar</b-card-title>
        <b-button
          variant="danger"
          size="sm"
          @click="setModal"
        >
          <FeatherIcon icon="UploadIcon" />
          Dosya Yükle
        </b-button>
      </b-card-header>
      <b-table
        :fields="fields"
        :items="dataItem.files"
      >
        <template #cell(document_type)="data">
          <b-link
            :href="baseUrl + '/media/sales/' + data.item.filename"
            target="_blank"
          >
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <b-button
                  :href="baseUrl + '/media/sales/' + data.item.filename"
                  target="_blank"
                  variant="flat-primary"
                  pill
                  class="btn-icon"
                >
                  <FeatherIcon
                    icon="DownloadIcon"
                  />
                </b-button>
              </div>
              <div>
                {{ data.item.document_type }}
                <div class="text-warning font-small-2">
                  {{ moment(data.item.created).format('llll') }}
                </div>
              </div>
            </div>
          </b-link>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="uploadModal"
      centered
      hide-footer
      title="Dosya Yükle"
    >
      <upload-form :get-data="getData" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BRow, BCol, BTable, BButton, BLink, BModal,
} from 'bootstrap-vue'
import UploadForm from '@/views/Sales/Sale_documents/UploadForm.vue'

export default {
  name: 'SaleDocumentView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BTable,
    BButton,
    BLink,
    BModal,
    UploadForm,
  },
  data() {
    return {
      uploadModal: false,
      baseUrl: this.$store.state.app.baseURL,
      fields: [
        {
          key: 'document_type',
          label: 'Evrak Tipi',
        },
        {
          key: 'username',
          label: 'Yükleme',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['saleDocuments/dataItem']
    },
  },
  created() {
    this.getData()
    this.getDocumentTypes()
  },
  methods: {
    setModal() {
      this.$store.commit('saleDocuments/RESET_UPLOAD_DATA')
      this.uploadModal = true
    },
    getData() {
      this.$store.dispatch('saleDocuments/getDataItem', this.$route.params.id)
    },
    getDocumentTypes() {
      this.$store.dispatch('saleDocumentTypes/getDataList', {
        select: [
          'com_sale_document_types.id AS id',
          'com_sale_document_types.title AS title',
        ],
      })
    },
  },
}
</script>
