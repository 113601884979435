<template>
  <div>
    <b-alert
      v-if="saveStatus.message"
      show
      :variant="saveStatus.status === true? 'success' : 'danger'"
    >
      <div class="alert-body text-center">
        {{ saveStatus.message }}
      </div>
    </b-alert>
    <template v-else-if="uploading">
      <pre-loading />
    </template>
    <template v-else>
      <b-form-group
        label="Evrak Tipi"
        label-for="id_com_sale_document_types"
      >
        <v-select
          id="id_com_sale_document_types"
          v-model="uploadData.id_com_sale_document_types"
          :options="documentTypes"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
      <b-form-group
        label="Evrak Tipi"
        label-for="id_com_sale_document_types"
      >
        <b-form-file
          v-model="uploadData.upload_file"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosya buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
      <div class="text-center">
        <b-button
          variant="success"
          :disabled="!uploadData.upload_file || !uploadData.id_com_sale_document_types"
          @click="submitForm"
        >
          <FeatherIcon icon="UploadIcon" />
          Dosyayı Yükle
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BFormFile, BFormGroup, BButton, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'UploadForm',
  components: {
    BFormGroup,
    BFormFile,
    BButton,
    BAlert,
    vSelect,
    PreLoading,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    uploadData() {
      return this.$store.getters['saleDocuments/uploadData']
    },
    documentTypes() {
      return this.$store.getters['saleDocumentTypes/dataList']
    },
    uploading() {
      return this.$store.getters['saleDocuments/uploading']
    },
    saveStatus() {
      return this.$store.getters['saleDocuments/dataSaveStatus']
    },
  },
  methods: {
    submitForm() {
      this.uploadData.id_com_sale_orderform = this.$route.params.id
      if (this.uploadData.id_com_sale_orderform) {
        this.$store.dispatch('saleDocuments/saveData', this.uploadData)
          .then(response => {
            if (response) {
              this.getData()
            }
          })
      }
    },
  },
}
</script>
